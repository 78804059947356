import LogoTitle from "../../assets/images/logo-a.png";
import { Link } from "react-router-dom";
import "./Home.scss";
import AnimatedLetters from "../Animated Letters/AnimatedLetters";
import { useEffect, useState } from "react";
import Loaders from "react-loaders";
import myImg from "../../assets/images/home-image.png";

const Home = () => {
  const [letterClass, setLetterClass] = useState("text-animate");
  const nameArray = ["l", "e", "x", "a", "n", "d", "e", "r", ","];
  const jobArray = [
    "W",
    "e",
    "b",
    " ",
    "D",
    "e",
    "v",
    "e",
    "l",
    "o",
    "p",
    "e",
    "r",
    ".",
  ];

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 4000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span>
            <img src={LogoTitle} alt="developer" />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={23}
            />
          </h1>
          <h2>Frontend Developer / React Developer / Web Developer</h2>
          <Link to="/contact" className="flat-button">
            CONTACT ME
          </Link>
          <Link to="/Alex.pdf" target="_blank" className="flat-button">
            VIEW MY RESUME
          </Link>
        </div>
        <div className="content">
          <img className="myImg" src={myImg} alt="My home logo" />
        </div>
      </div>
      <Loaders type="pacman" />
    </>
  );
};

export default Home;
