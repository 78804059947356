import { Link, NavLink } from "react-router-dom";
import "./MobileNav.scss";
import Logoa from "../../assets/images/logo-a.png";
import { useState } from "react";
import Navbar from "../Navbar/Navbar";
// import LogoSubtitle from "../../assets/images/logo_sub.png";
// import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const MobileNav = () => {
  const [isVisible, setisVisible] = useState(false);

  const toggleMenu = () => {
    setisVisible((prevValue) => !prevValue);
  };
  const closeMenu = () => {
    setisVisible(false);
  };
  return (
    <>
      <div className="nav-bar-mobile">
        <Link className="logo" to="/">
          <img src={Logoa} alt="logo" />
        </Link>

        <button className="toggle-button" onClick={toggleMenu}>
          {!isVisible ? <p>☰</p> : undefined}
        </button>
        {isVisible && (
          <>
            <nav>
              <div className="nav-wrapper">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                  end
                  onClick={closeMenu}
                >
                  Home
                </NavLink>
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                  onClick={closeMenu}
                >
                  About Me
                </NavLink>
                <NavLink
                  to="/contact"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                  onClick={closeMenu}
                >
                  Contact
                </NavLink>
                {/* <NavLink
        to="/blog"
        className={({ isActive }) => (isActive ? "active" : undefined) 
                  onClick={closeMenu}
    }
      >
        Blog
      </NavLink> */}
                <NavLink
                  to="/project"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                  onClick={closeMenu}
                >
                  Projects
                </NavLink>
              </div>
            </nav>
            <button className="close-button" onClick={toggleMenu}>
              X
            </button>
            {!isVisible && <Navbar />}
          </>
        )}

        {/* <ul>
      <li>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://github.com/zerootwooooo"
        >
          <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
        </a>
      </li>
      <li>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/alexander-arevalo-871940298/"
        >
          <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
        </a>
      </li>
    </ul> */}
      </div>
    </>
  );
};

export default MobileNav;
