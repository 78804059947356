import "./Contacts.scss";
import Loaders from "react-loaders";
import AnimatedLetters from "../Animated Letters/AnimatedLetters";
import { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import { useSnackbar } from "notistack";

export const Contact = () => {
  const [letterClass, setLetterClass] = useState("text-animate");
  const { enqueueSnackbar } = useSnackbar();

  const refForm = useRef();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    console.log("Form data:", refForm.current); // Log form data to the console

    const serviceId = "service_zk9q0dx";
    const templateId = "template_sr7b8m7";
    const userId = "vfQbtMzwuO1Ag2zgX";
    emailjs.sendForm(serviceId, templateId, refForm.current, userId).then(
      (response) => {
        console.log("Success:", response);
        // alert("Message Successfully Sent!");
        // enqueueSnackbar("Message Sent Succesfully", { variant: "success" });
        enqueueSnackbar("Message Sent Succesfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom", // 'top' or 'bottom'
            horizontal: "right", // 'left', 'center', or 'right'
          },
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
      },
      (error) => {
        console.error("Error sending message:", error);
        // alert("failed to send the message, please try again");
        enqueueSnackbar("Error", { variant: "error" });
      }
    );
  };

  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={["C", "o", "n", "t", "a", "c", "t", " ", "m", "e"]}
              idx={15}
            />
          </h1>
          <p>
            I am actively seeking a position as a React Web Developer.
            Currently, I am not employed and am fully dedicated to finding a job
            that aligns with my skills and expertise in React development.
          </p>
        </div>
        <div className="contact-form">
          <form ref={refForm} onSubmit={sendEmail}>
            <ul>
              <li className="half">
                <input
                  type="text"
                  name="from_name"
                  placeholder="Name"
                  required
                />
              </li>
              <li className="half">
                <input
                  type="email"
                  name="from_email"
                  placeholder="Email"
                  required
                />
              </li>
              <li>
                <input
                  type="text"
                  name="from_subject"
                  placeholder="Subject"
                  required
                />
              </li>
              <li>
                <textarea
                  placeholder="Message"
                  name="message"
                  required
                ></textarea>
              </li>
              <li>
                <input type="submit" className="flat-button" value="SEND" />
              </li>
            </ul>
          </form>
        </div>
      </div>
      <Loaders type="pacman" />
    </>
  );
};

export default Contact;
