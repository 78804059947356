import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout.js";
import Home from "./components/Home/Home.js";
import About from "./components/About/About.js";
import Contact from "./components/Contacts/Contacts.js";
import Project from "./components/Projects/Projects.js";
// import Blog from "./components/Blog/Blog.js";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          {/* <Route path="blog" element={<Blog />} /> */}
          <Route path="project" element={<Project />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
