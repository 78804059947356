import { Link, NavLink } from "react-router-dom";
import "./Navbar.scss";
import Logoa from "../../assets/images/logo-a.png";
import MobileNav from "../MobileNav/MobileNav";
// import LogoSubtitle from "../../assets/images/logo_sub.png";
// import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Navbar = () => (
  <>
    <div className="nav-bar">
      <Link className="logo" to="/">
        <img src={Logoa} alt="logo" />
        {/* <img className="sub-logo" src={LogoSubtitle} alt="alexander" /> */}
      </Link>
      <nav>
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? "active" : undefined)}
          end
        >
          Home
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) => (isActive ? "active" : undefined)}
        >
          About Me
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) => (isActive ? "active" : undefined)}
        >
          Contact
        </NavLink>
        {/* <NavLink
        to="/blog"
        className={({ isActive }) => (isActive ? "active" : undefined)}
      >
        Blog
      </NavLink> */}
        <NavLink
          to="/project"
          className={({ isActive }) => (isActive ? "active" : undefined)}
        >
          Projects
        </NavLink>
      </nav>

      {/* <ul>
      <li>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://github.com/zerootwooooo"
        >
          <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
        </a>
      </li>
      <li>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/alexander-arevalo-871940298/"
        >
          <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
        </a>
      </li>
    </ul> */}
    </div>
    <div className="nav-bar-mobile">
      <MobileNav />
    </div>
  </>
);

export default Navbar;
